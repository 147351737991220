@import 'https://library-sdb.apps.bancolombia.com/bds/6.58.1/bds.min.css';
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(assets/styles/iconosMaterial.woff2) format('woff2');
  }
  
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }
html,
body {
    background-color: #F2F2F2;
}

.error-select input {
    border-bottom: 2px solid #fa5e5b !important;
}

.separator {
    border: 0.5px solid #E6E7E8 !important;
    margin-bottom: 18px !important;
    margin-top: 18px !important;
}

.secondary-text {
    font-family: Open Sans, Sans-serif, serif !important;
    font-weight: 400;
    font-style: normal !important;
    font-size: 14px !important;
    line-height: 18px !important;
    letter-spacing: -0.3px !important;
    color: #808285 !important;
    margin-bottom: 0;
}

hr {
    height: 1px;
    border: 0;
    background-color: #e6e7e8;
    border-radius: 4px;
    margin-top: 1%;
}


/* mostrar el calendario al hacer click */

input[type="date"]::-webkit-calendar-picker-indicator {
    display: block;
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}


/* Estilos de angular material */

.mat-input-element {
    font-size: 16px !important;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container,
.cdk-overlay-connected-position-bounding-box {
    z-index: 99999 !important;
}

.btn-little {
    height: 24px;
    width: 17%;
    border-radius: 19px;
    font-size: 15px;
}

@media only screen and (max-width: 578px),
(min-width: 578px) and (max-width: 1023px) {
    .bc-menu-submenu {
        height: 32px !important;
        width: 100px !important;
        display: block !important;
    }
    .bc-submenu-title {
        display: none;
    }
    .bc-menu-item-options {
        display: none;
    }
    .bc-menu-drawer-content>div:nth-child(5) {
        display: none;
    }
}

.bc-header-wrapper.bc-fixed {
    position: fixed;
}

.bc-header-wrapper {
    background-color: #fff;
    width: 100%;
    z-index: 999;
    left: 0;
    top: 0;
    border-bottom: 2px solid #dadada;
}

.bc-avatar,
.bc-header-wrapper {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1), 0 0 5px 0 rgba(0, 0, 0, .1);
}


/* Estilos tabs */

.bc-tabs {
    justify-content: center;
}

.bc-tabs-group {
    padding: 0;
    margin: 40px 0 0;
}

.bc-tabs-group .bc-tab-header .bc-tabs .active {
    border-radius: 8px 8px 0 0;
    border-bottom: 3px solid #2C2A29;
    background: #fdda24;
}

.bc-tabs-group .bc-tab-header .bc-tabs{
    border-bottom: 0;
}

/* .bc-tab {
    background: white;
} */

.bc-tabs-border {
    background-color: transparent ; 
}


/* Estilos tabs ^ */


/* Estilos menu */

.bc-menu-item:hover {
    background-color: #fdda24;
}


/* Estilos menu */


/*Estilos tabla material*/

.mat-header-cell,
.mat-cell {
    font-family: Open Sans, Sans-serif, serif;
    font-style: normal;
    font-size: 14px !important;
}


/*Estilos tabla material*/


/* Estilos scroll */

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}


/* Estilos scroll */

.bc-container-solid {
    background: #f2f2f2;
}

.bc-invalid-feedback,
.mat-error {
    color: #e20201 !important;
    font-weight: 700;
    font-size: 12px;
}

article.bc-alert-fixed {
    top: 75% !important;
}


/*Ajuste linea de input Bug 3447739*/

.bc-input-area[aria-label]:not(:placeholder-shown),
.bc-input-area[id]:not(:placeholder-shown),
.bc-input[aria-label]:not(:placeholder-shown),
.bc-input[id]:not(:placeholder-shown) {
    border-bottom: 1px solid black !important;
}

.bc-input-area[aria-label]:focus,
.bc-input-area[id]:focus,
.bc-input[aria-label]:focus,
.bc-input[id]:focus {
    border-bottom: 2px solid #fdda24 !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: black !important;
}


/*Ajuste linea de input Bug 3447739*/

/* Se ajusta ancho en el titulo de la app en el header */
.bc-header-brand .bc-header-channel h5 {
    max-width: none;
}

/* se ajusta ancho del nombre de asesor en el header */
.bc-header-nav-item-text p {
    max-width: none;
}