@use '@angular/material' as mat;
@include mat.core();
@import '../../styles.css';

$primary-colors: (
        100: #FFEE58,
        200: #FFEE58,
        500: #ffcf01,
        700: black,
        contrast:(
                200:#FFFFFF,
                500:black,
                700:black
        )
);

$palette-primary: mat.define-palette($primary-colors);
$palette-warn: mat.define-palette($primary-colors);
$palette-accent: mat.define-palette($primary-colors);
$theme: mat.define-light-theme($palette-primary, $palette-accent);
@include mat.all-component-themes($theme);
